import $ from "jquery";
import "jquery.marquee";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", function () {
  $(document).ready(function () {
    $(".scrolling-wrapper").marquee({
      duration: 15000,
      delayBeforeStart: -100000,
      direction: "left",
      duplicated: true,
    });
  });

  $(document).ready(function () {
    $(".scrolling-wrapper-down").marquee({
      duration: 15000,
      delayBeforeStart: -100000,
      direction: "right",
      duplicated: true,
    });
  });

  const burgerButton = document.querySelector(
    ".header-nav-burger-button-container"
  );
  const closeButton = document.querySelector(
    ".header-nav-content-close-container"
  );
  const navContent = document.querySelector(".header-nav-content");
  const navLeft = document.querySelector(".header-nav-content-left");
  const navLinks = document.querySelectorAll(".header-nav-content-a");

  function toggleMenu() {
    navContent.classList.toggle("active");
  }

  burgerButton.addEventListener("click", toggleMenu);
  closeButton.addEventListener("click", toggleMenu);
  navLeft.addEventListener("click", toggleMenu);

  navLinks.forEach((link) => {
    link.addEventListener("click", toggleMenu);
  });
});

const tlHeader = gsap.timeline({
  scrollTrigger: {
    trigger: "#header",
    start: "top top",
    end: "bottom top",
    scrub: true,
  },
});

tlHeader.to(".header-h1:nth-child(1)", { left: "90%", ease: "power2.out" }, 0);
tlHeader.to(".header-h1:nth-child(2)", { left: "-50%", ease: "power2.out" }, 0);
tlHeader.to(".header-h1:nth-child(3)", { left: "50%", ease: "power2.out" }, 0);
tlHeader.to(".header-h1:nth-child(4)", { left: "-50%", ease: "power2.out" }, 0);
tlHeader.to(".header-h1:nth-child(5)", { left: "50%", ease: "power2.out" }, 0);
